import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Box } from '@mui/material';
import instance from '../../../../../auth/utils/useJwt';
import FileUploadIcon from '@mui/icons-material/FileUpload';

export default function MediaUpload({ onUpload }) {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress
    const fileInputRef = useRef(null);

    const uploadFile = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);
        setUploadProgress(0); // Reset progress

        instance.post('/admin/file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
                const progress = Math.round(
                    (progressEvent.loaded / progressEvent.total) * 100
                );
                setUploadProgress(progress); // Update progress
            },
        })
        .then((res) => {
            console.log(res.data);
            onUpload(res.data.file);
            fileInputRef.current.value = '';
        })
        .catch((err) => {
            console.error('Error uploading file:', err);
        })
        .finally(() => {
            setIsLoading(false);
            setUploadProgress(0); // Reset progress after upload
        });
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={uploadFile}
                disabled={isLoading}
            />
            <Button
                variant="contained"
                startIcon={!isLoading && <FileUploadIcon />}
                color="primary"
                onClick={handleButtonClick}
                disabled={isLoading}
            >
                {isLoading ? (
                    <Box display="flex" alignItems="center" gap={1}>
                        <CircularProgress size={24} color="inherit" />
                        {uploadProgress > 0 && `${uploadProgress}%`}
                    </Box>
                ) : (
                    'Upload File'
                )}
            </Button>
        </Box>
    );
}
