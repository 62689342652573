import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import { Button, Checkbox, Stack, Typography } from '@mui/material';
import instance from '../../../../../auth/utils/useJwt';
export default function PublishCourseDialog({targetDate, courseUsers, onPublishSuccess}) {
    const { id } = useParams();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [sendEmail, setSendEmail] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handlePublish = () => {
        setError(null);
        setLoading(true);
        instance.patch(`/admin/training/course`, { course_id: id, status: 'PUBLISHED' })
            .then(res => {
                setOpen(false);
                setLoading(false);
                onPublishSuccess();
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                setError("An error occurred while publishing course");
            }
            )
    }


    return (
        <>
            <Button onClick={handleClickOpen} size='small' variant="contained">Publish</Button>
            <GeneralDialog open={open} handleClose={() => setOpen(false)} title="Publish Course" actions={
                <Stack spacing={2} direction="row">
                    <Button onClick={handlePublish} variant="contained" disabled={loading} size='small'
                    >
                        {loading ? "Publishing..." : "Confirm"}
                    </Button>
                </Stack>
            }>
                <Typography>You are about to publish training for {courseUsers?.length} users to be completed on {targetDate}. 
                    Click confirm to publish to users.</Typography>

                <Stack direction="row" gap={1} sx={{ mt: 1 }}>
                    <Checkbox  sx={{ ml: 0 }} checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />
                    <Typography>Send email to participants inviting them to complete this training course</Typography>
                </Stack>
                {
                    error && <p style={{ color: 'red' }}>{error}</p>
                }
            </GeneralDialog>

        </>
    )
}