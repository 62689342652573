import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog'
import instance from '../../../../../auth/utils/useJwt'
import { Box, Checkbox, CircularProgress, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useParams } from 'react-router-dom'

function SingleCategoryTable({ category, data, selectedTrainingUnits, setSelectedTrainingUnits }) {
    const onCheckboxChange = (e, id) => {
        if (e.target.checked) {
            setSelectedTrainingUnits([...selectedTrainingUnits, id])
        } else {
            setSelectedTrainingUnits(selectedTrainingUnits.filter(item => item !== id))
        }
    }
    return (
        <Box>
            <Box sx={{ backgroundColor: "#fce8e6", px: 2, py: 1 }}>
            {category}
            </Box>
            <Table size="small">
                <TableHead>
                    <TableRow sx={{ backgroundColor: "#F5F5F5" }}>
                        <TableCell></TableCell>
                        <TableCell>Training Unit</TableCell>
                        <TableCell>Duration</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(item => {
                        return (
                            <TableRow sx={{ py: 0 }}>
                                <TableCell>
                                    <Checkbox size='small'
                                        checked={selectedTrainingUnits.includes(item.id)}
                                        onChange={(e) => onCheckboxChange(e, item.id)} />
                                </TableCell>
                                <TableCell>{item.title}</TableCell>
                                <TableCell>{item.reading_minutes}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </Box>
    )
}

export default function TrainingCourseUnitsAssignment({ onUpdate }) {
    const { id: course_id } = useParams()

    const [selectedUnitsDialog, setSelectedUnitsDialog] = useState(false)

    const [selectedTrainingUnits, setSelectedTrainingUnits] = useState([])
    const [unitsCategories, setUnitsCategories] = useState([])

    const [unasignedTrainingUnits, setUnassignedTrainingUnits] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const fetchCourse = () => {
        instance.get(`/admin/training`).then(res => {
            setUnassignedTrainingUnits(res.data?.filter(item => item.course_id === null))
            const alreadyAssigned = res.data?.filter(item => item.course_id === parseInt(course_id))
            // set ids to selectedTrainingUnits
            setSelectedTrainingUnits(alreadyAssigned.map(item => item.id))
        }).catch(err => {
            console.log(err)
        })
    }

    // Fetch unique categories from training units
    useEffect(() => {
        const categories = [...new Set(unasignedTrainingUnits.map(item => item.category))]
        setUnitsCategories(categories)

    }, [unasignedTrainingUnits])


    const updateTrainingUnits = async () => {
        setLoading(true)
        setError(null)
        const data = {
            course_id,
            training_units: selectedTrainingUnits
        }
        const res = await instance.post(`/admin/training/course/assignment`, data)
        if (res.status !== 200) {
            setLoading(false)
            setError("Unable to update training units")
            return
        }
        setLoading(false)
        setSuccess(true)
        setSelectedUnitsDialog(false)
        onUpdate()
    }


    useEffect(() => {
        if (course_id) {
            fetchCourse()
        }
    }, [course_id])


    return (
        <Box>

            <Button size='small' variant='contained' color='primary' onClick={() => setSelectedUnitsDialog(true)}>
                Select Training Units
            </Button>
            <GeneralDialog open={selectedUnitsDialog} handleClose={() => setSelectedUnitsDialog(false)} maxWidth="xlg" title="Select Training Units"
                actions={<Stack direction="row" spacing={2}>
                    <Button color="primary" variant="contained" size="small"
                        disabled={loading}
                        onClick={updateTrainingUnits}
                    >
                        {loading ? <CircularProgress size={24} /> : "Save"}
                    </Button>
                </Stack>}
                dialogContentSx={{
                    p: 0
                }}
                dialogTitleSx={{
                    mb: 0
                }}
            >
                <Box sx={{ minWidth: '700px' }}>
                    {unitsCategories.length > 0 &&
                        unitsCategories.map((category, index) => {
                            return <SingleCategoryTable
                                key={`cat-table-${index}`}
                                category={category}
                                data={unasignedTrainingUnits.filter(item => item.category === category)}
                                selectedTrainingUnits={selectedTrainingUnits}
                                setSelectedTrainingUnits={setSelectedTrainingUnits}
                            />
                        }
                        )}

                </Box>
            </GeneralDialog>




        </Box>
    )
}