import React, { useState } from "react";
import Box from '@mui/material/Box'
import { useParams } from "react-router-dom";
import { Card, Stack } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import AppLayout from "../../../../components/layout/AppLayout";
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import useSnackbar from "../../../../components/Dialogs/SnackBar";
import CourseMetaData from "./MetaData";
import TrainingUnits from "./TrainingUnits";
import TrainingCourseUsers from "./CourseUsers";
import PublishCourseDialog from "./components/PublishDialog";

export default function AdminTrainingsCourseDetails() {
    const { id: course_id } = useParams()
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [courseUsers, setCourseUsers] = useState([])
    const [courseStatus, setCourseStatus] = useState(null)
    const [targetDate, setTargetDate] = useState(null)


    return (
        <AppLayout title="Admin - Training Course"
            px={0} apppadding={"0px"}
        >
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Training Courses", url: "/admin/training" },
                        { text: course_id === "new" ? "Add New" : "Course Details" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >
                    {courseStatus && courseStatus === "DRAFT" ?
                        <PublishCourseDialog courseUsers={courseUsers} targetDate={targetDate}
                            onPublishSuccess={() => showSnackbar("Course published successfully", "success")} />
                        :
                        <>
                            {courseStatus === "PUBLISHED" &&
                                <Stack direction="row" alignItems={"center"} sx={{ fontSize: '18px', fontWeight: 500, color: "#063e29" }}>
                                    <CheckCircleIcon /> Published
                                </Stack>
                            }
                        </>
                    }
                </Box>

            </BContainer>
            <SnackbarComponent />
            <Box sx={{ px: 3, mb: 2 }}>
                <Card sx={{ mb: 2 }}>
                    <CourseMetaData course_id={null} onTargetDateChange={setTargetDate} onCourseStatusChange={setCourseStatus} />
                </Card>
                {(course_id && course_id !== "new") && <>
                    <Card>
                        <TrainingUnits status={courseStatus} />
                    </Card>
                    <Card sx={{ mt: 2 }}>
                        <TrainingCourseUsers course_id={null} onUsersChange={setCourseUsers} status={courseStatus} />
                    </Card>
                </>
                }

            </Box>

        </AppLayout>
    )
}