import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import instance from '../../../../auth/utils/useJwt'
import { Alert, Box, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import MuiDataGrid from '../../../../components/tables/MUIDataGrid'
import DeleteIcon from '@mui/icons-material/Delete';
import TrainingCourseUnitsAssignment from './components/CourseUnitAssignment'
import TrainingCardHeader from './components/TrainingCardHeader'

export default function TrainingUnits({ status }) {
    const { id: course_id } = useParams()

    const [assignedTrainingUnits, setAssignedTrainingUnits] = useState([])
    const [selectedTrainingUnits, setSelectedTrainingUnits] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [targetDate, setTargetDate] = useState(null)

    const fetchCourse = () => {
        setLoading(true)
        instance.get(`/admin/training`).then(res => {
            setAssignedTrainingUnits(res.data?.filter(item => item.course_id === parseInt(course_id)))
            setLoading(false)
        }).catch(err => {
            console.log(err)
            setError("An error occured")
            setLoading(false)
        })
    }

    useEffect(() => {
        if (course_id) {
            fetchCourse()
        }
    }, [course_id])

    const deleteTrainingUnit = (id) => {
        // just remove from the assignedTrainingUnits
        const newAssignedTrainingUnits = assignedTrainingUnits.filter(item => item.id !== id)
        setAssignedTrainingUnits(newAssignedTrainingUnits)
    }

    const updateTrainingUnits = async () => {
        setLoading(true)
        setError(null)
        const data = {
            course_id,
            training_units: assignedTrainingUnits.map(item => item.id),
            selected_training_units: selectedTrainingUnits,
            target_date: targetDate
        }
        instance.post(`/admin/training/course/assignment`, data).then(res => {
            console.log(res)
            setLoading(false)
            setSuccess(true)
            setTargetDate(null)
            fetchCourse()
        }).catch(err => {
            console.log(err)
            setError("An error occured")
            setLoading(false)
        })
    }


    return (
        <Box>
            <TrainingCardHeader justifyContent={"space-between"} direction={"row"}>
                <Typography>
                    Training Units
                </Typography>
                {status !== "PUBLISHED" &&
                    <TrainingCourseUnitsAssignment onUpdate={fetchCourse} course_id={course_id} />}
            </TrainingCardHeader>

            <Box sx={{ mt: 2 }}>
                <MuiDataGrid
                    loading={loading}
                    checkboxSelection={status !== "PUBLISHED"}
                    baseFilters={false}
                    onRowSelectionModelChange={(params) => {
                        setSelectedTrainingUnits(params)
                    }}
                    columns={[
                        { field: 'title', headerName: 'Training Unit', flex: 1 },
                        {
                            field: 'reading_minutes', headerName: 'Duration', width: 130, renderCell: (params) => {
                                return params.row.reading_minutes ? `${params.row.reading_minutes} mins` : "Not Set"
                            }
                        },
                        {
                            field: 'actions', headerName: 'Actions', width: 130, renderCell: (params) => {
                                return (
                                    <Stack direction="row" spacing={1}>
                                        <IconButton size="small" onClick={() => deleteTrainingUnit(params.row.id)} color="error">
                                            <DeleteIcon />
                                        </IconButton>
                                    </Stack>
                                )
                            }
                        }
                    ]} data={assignedTrainingUnits} />

            </Box>

            <Box sx={{ px: 3, pb: 2 }}>
                {error && <Alert severity="error">{error}</Alert>}
                {status !== "PUBLISHED" &&
                    <Stack direction="row" spacing={2} justifyContent={"flex-end"} sx={{ pt: 1 }}>

                        <Button onClick={updateTrainingUnits} color="primary" size='small' variant='contained'>
                            {loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Update"}
                        </Button>
                    </Stack>
                }
            </Box>



        </Box>
    )
}