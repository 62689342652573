import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { formatDate } from '../../components/forms/utils';
import { DATE_FORMAT } from '../../configs/basic';


export default function DatePicker(props) {
    const { name, value, sx={} } = props

    const onChange = (e) => {
        var dt = {
            name: name, value: formatDate(e?.$d)
        }
        if (props.onChange){
            props.onChange(dt)
        }
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiDatePicker
                format={DATE_FORMAT} onChange={onChange} name={name}
                value={value ? dayjs(value, DATE_FORMAT) : null}
                label={props.label}
                sx={{ width: '150px', ...sx }}
                slotProps={{ textField: { size: 'small', width: '180px' } }}
                defaultValue={value ? dayjs(value, DATE_FORMAT) : null} />
        </LocalizationProvider>
    )
}