import { Box, Button, Card, Grid, MenuItem, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CustomInputLabel from "../../../../../components/forms/CustomInputLabel";
import instance from "../../../../../auth/utils/useJwt";
import BaseCard from "../components/BaseCard";

export default function ReportFilters({onSubmit}) {
    const [selectedCourse, setSelectedCourse] = useState("")
    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get("/admin/training/course").then(res => {
            setLoading(false)
            setCourses(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])

    const onSubmitData = () => {
        onSubmit({
            course_id: selectedCourse
        })
    }

    return (
        <BaseCard title="Filters">

            <Box sx={{ pb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <Box>
                            <CustomInputLabel>
                                Select Course
                            </CustomInputLabel>
                            <Select size="small" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} fullWidth>
                                {
                                    courses.map(course => (
                                        <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Grid>
                    <Grid item md={4}>
                        <Box>
                            <CustomInputLabel>
                                Filter Type
                            </CustomInputLabel>
                            <Select size="small" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} fullWidth>
                                {
                                    [
                                        { id : 'all', name: 'All' },
                                        { id : 'completed', name: 'Completed' },
                                        { id : 'incomplete', name: 'Incomplete' },
                                    ].map(course => (
                                        <MenuItem key={course.id} value={course.id}>{course.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </Box>
                    </Grid>
                </Grid>
            </Box>


            <Stack direction="row" justifyContent={"flex-end"} spacing={2} sx={{ pb: 1, pt: 2, px: 2 }}>
                <Button variant="contained" size="small" color="primary" onClick={onSubmitData}
                disabled={loading || !selectedCourse}
                >{ loading ? "Loading..." : "Filter" }
                </Button>
                <Button variant="contained" size="small" color="error" onClick={() => setSelectedCourse(null)}
                    disabled={loading || !selectedCourse}
                    >Reset</Button>
            </Stack>

        </BaseCard>
    )
}