import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ReplayIcon from '@mui/icons-material/Replay';
import QuizOptions from "./QuizOptions";
import instance from "../../../../../auth/utils/useJwt";

export default function TrainingQuiz({ trainingSection, nextSection, training }) {
    
    const [quizSubmitted, setQuizSubmitted] = useState(false)
    const [quizResult, setQuizResult] = useState(null)

    const [quiz, setQuiz] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState(1)
    const [selected, setSelected] = useState({})

    const handleOptionChange = (value, qid) => {
        setSelected({ ...selected, [qid]: value })
    }

    useEffect(() => {
        setCurrentQuestion(quiz[0])
    }, [quiz])

    const handleNext = () => {
        if (currentQuestionNumber < quiz.length) {
            setCurrentQuestion(quiz[currentQuestionNumber])
            setCurrentQuestionNumber(currentQuestionNumber + 1)
        }
    }

    const handlePrevious = () => {
        if (currentQuestionNumber > 1) {
            setCurrentQuestion(quiz[currentQuestionNumber - 2])
            setCurrentQuestionNumber(currentQuestionNumber - 1)
        }
    }


    useEffect(() => {
        setQuizSubmitted(false)
        setQuiz(trainingSection?.quiz)
        // set first question
        setCurrentQuestion(quiz[0])
        setCurrentQuestionNumber(1)
    }, [trainingSection?.quiz])
    
    const onSubmitClick = () => {
        // Check quiz with their answers, each question has key "answer"
        // If answer is same as selected, then correct
        let correct = 0
        quiz.forEach(question => {
            if (question.answer === selected[question.id]) {
                correct++
            }
        })

        setQuizResult({
            correct, total: quiz.length
        })


        // set quiz submitted
        instance.post(`/training/unit/quiz`, {
            data: {
                "questions" : quiz,
                "answers" : selected
            },
            training_id: training.id,
            correct: correct,
            total: quiz.length,
            training_unit_section_id: trainingSection.id
        })

        setQuizSubmitted(true)
    }

    const redoQuiz = () => {
        // clear selected
        setSelected({})
        setQuizSubmitted(false)

        // set to first question
        setCurrentQuestion(quiz[0])
        setCurrentQuestionNumber(1)
    }


    if (quizSubmitted) {
        return (
            <Box>
                <Card>
                    <Box sx={{ py: 2, px: 3 }}>
                        <Typography variant="h6">Quiz Result</Typography>
                        <Box sx={{ mt: 2 }}>
                        <Typography variant="body" sx={{ mt: 1 }}>You scored {quizResult.correct} out of {quizResult.total}</Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 2
                        }}>
                            {/* If quiz result is more than 75%, show next button, otherwise redo quiz */}
                            {quizResult.correct === quizResult.total ?
                                <Button variant="contained" size="small"
                                    endIcon={<NavigateNextIcon />}
                                    onClick={nextSection} color="primary">Next Section</Button> :
                                <Button variant="contained" size="small"
                                    startIcon={<ReplayIcon />}
                                    onClick={redoQuiz} color="primary">Redo Quiz</Button>
                            }
                        </Box>
                    </Box>
                </Card>
            </Box>
        )
    }


    return (
        <Box>
            {currentQuestion === null || currentQuestion === undefined ? <Box>No questions available</Box> :
                <Card>
                    <Box sx={{ py: 2, px: 3 }}>
                        <Stack direction="row" gap={2}>
                            <Typography variant="h6">Q.{currentQuestionNumber}</Typography>
                            <Box>
                                <Typography variant="h6" sx={{ color: "#FC8525", lineHeight: '1em', mb: 1, fontSize: '18px' }}>{currentQuestion.question}</Typography>


                                <QuizOptions
                                    options={currentQuestion.options}
                                    onChange={(value) => handleOptionChange(value, currentQuestion.id)}
                                    selected={selected[currentQuestion.id]} />
                            </Box>
                        </Stack>
                        <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ py: 1 }}>
                            <Box>
                                {currentQuestionNumber} of {quiz.length}
                            </Box>
                            <Stack direction="row" spacing={2}>

                                <Box>
                                    <Button size="small" variant="outlined"
                                        disabled={currentQuestionNumber === 1}
                                        onClick={handlePrevious}
                                    >Previous</Button>
                                </Box>
                                {currentQuestionNumber === quiz.length ?
                                    <Box>
                                        <Button onClick={onSubmitClick} size="small" variant="contained" color="primary">Submit</Button>
                                    </Box>
                                    :
                                    <Box>
                                        <Button size="small" variant="contained" color="primary"
                                            disabled={currentQuestionNumber === quiz.length}
                                            onClick={handleNext}
                                        >Next</Button>
                                    </Box>
                                }
                            </Stack>
                        </Stack>
                    </Box>
                </Card>
            }


        </Box>
    )
}