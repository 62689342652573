import React, { useEffect, useRef, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Card, CardContent, Grid, LinearProgress, MenuItem, Select, Stack, TextField } from "@mui/material";
import CustomInputLabel from "../../../../components/forms/CustomInputLabel";

import useSnackbar from "../../../../components/Dialogs/SnackBar";
import TextEditor from "../details/TextEditor";

export default function AdminTrainingsSectionDetails() {
    const [trainingDetails, setTrainingsDetails] = useState({
        title: "",
        content: "",
        reading_minutes: "",
        category: "",
        excerpt: '',
        content_list: [{
            title: "",
            content: ""
        }]
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const editorRef = useRef(null);
    const [refList, setRefList] = useState([useRef(null)])
    const { id } = useParams()
    const navigate = useNavigate()

    const fetchTrainingDetails = () => {
        setLoading(true)
        setError(null)
        instance.get(`/admin/training/section?id=${id}`).then(res => {
            setLoading(false)
            // if no content_list, then create it
            if (!res.data.content_list)
                res.data.content_list = [{
                    title: "",
                    content: res.data.content
                }]

            setTrainingsDetails(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
            setError("Unable to fetch training details")
        })
    }

    useEffect(() => {
        if (id !== "new")
            fetchTrainingDetails()
    }, [])

    const onInputChange = (e) => {
        setTrainingsDetails({
            ...trainingDetails,
            [e.target.name]: e.target.value
        })
    }

    const onSave = () => {
        setLoading(true)
        
        // trainingDetails.content = editorRef.current.getContent()
        if (id !== "new") {
            trainingDetails.id = id
            instance.patch(`/admin/training/section`, trainingDetails).then(res => {
                setLoading(false)
                showSnackbar("Training updated successfully")
                navigate("/admin/training/units")
            }).catch(err => {
                console.log(err)
                setLoading(false)
                showSnackbar("Unable to update training", "error")
            })
        }
        else {
            instance.post(`/admin/training/section`, trainingDetails).then(res => {
                setLoading(false)
                showSnackbar("Training added successfully")
                navigate("/admin/training/units")
            }).catch(err => {
                console.log(err)
                setLoading(false)
                showSnackbar("Unable to add training", "error")
            })
        }
    }

    const addTrainingSubSection = () => {
        // Add to both content_list and refList
        let list = trainingDetails.content_list
        // if list is undefined, then create it
        if (!list)
            list = []
        list.push({
            title: "",
            content: ""
        })
        setTrainingsDetails({
            ...trainingDetails,
            content_list: list
        })
    }

    const onTitleChange = (index, e) => {
        let list = trainingDetails.content_list
        list[index].title = e.target.value
        setTrainingsDetails({
            ...trainingDetails,
            content_list: list
        })
    }


    return (
        <AppLayout title="Admin - Trainings"
            px={0} apppadding={"0px"}
        >
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Training Units", url: "/admin/training/units" },
                        { text: "Training Section" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >

                </Box>

            </BContainer>
            <SnackbarComponent />
            <Box sx={{ px: 3, mb: 2 }}>
                <Card>
                    {
                        loading ? <LinearProgress /> : null
                    }
                    <CardContent>
                        {
                            error ? <Alert sx={{ mb: 2 }} severity="error">{error}</Alert> : null
                        }
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <Stack direction={"column"} spacing={2}>
                                    <Box>

                                        <CustomInputLabel>
                                            Title
                                        </CustomInputLabel>
                                        <TextField size="small" value={trainingDetails.title} fullWidth onChange={onInputChange} name="title" />
                                    </Box>
                                    <Box>
                                        <CustomInputLabel>
                                            Reading Minutes
                                        </CustomInputLabel>
                                        <TextField size="small" value={trainingDetails.reading_minutes} fullWidth onChange={onInputChange} name="reading_minutes" />
                                    </Box>
                                    
                                </Stack>
                            </Grid>

                            <Grid item md={8}>
                                <CustomInputLabel>
                                    Content
                                </CustomInputLabel>
                                {/* <TextField multiline rows={10} fullWidth value={trainingDetails.content} onChange={onInputChange} name="content" /> */}
                                {
                                    trainingDetails?.content_list?.map((item, index) => (
                                        <Box key={index}>
                                            <CustomInputLabel>
                                                Title
                                            </CustomInputLabel>
                                            <TextField size="small" value={item.title} fullWidth onChange={(e) => {
                                                onTitleChange(index, e)
                                            }} />
                                            <CustomInputLabel>
                                                Content
                                            </CustomInputLabel>
                                            <TextField multiline rows={5} fullWidth value={item.content} onChange={(e) => {
                                                let list = trainingDetails.content_list
                                                list[index].content = e.target.value
                                                setTrainingsDetails({
                                                    ...trainingDetails,
                                                    content_list: list
                                                })
                                            }
                                            } />
                                        </Box>
                                    ))
                                }
                                <Button variant="contained" color="primary" onClick={addTrainingSubSection}>Add Content</Button>
                                {/* <TextEditor value={trainingDetails.content} onChange={onInputChange} name="content" editorRef={editorRef} /> */}
                            </Grid>
                        </Grid>

                        <Box>
                            <Stack direction="row" spacing={2} justifyContent={"flex-end"} sx={{ pt: 2 }}>
                                <Button variant="contained" color="primary"
                                    onClick={onSave} disabled={loading}
                                >Save</Button>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
            </Box>

        </AppLayout>
    )
}