import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import instance from '../../../../auth/utils/useJwt'
import { Alert, Box, CardContent, CircularProgress, Grid, IconButton, Skeleton, Stack, TextField } from '@mui/material'
import CustomInputLabel from '../../../../components/forms/CustomInputLabel'
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom'
import MediaPicker from '../details/MediaPicker'
import DatePicker from '../../../../components/forms/DatePicker'
import useSnackbar from '../../../../components/Dialogs/SnackBar'
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const TextContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    fontSize: '18px',
    marginTop: theme.spacing(0.5),
}));


export default function CourseMetaData({ onTargetDateChange, onCourseStatusChange }) {
    const { id: course_id } = useParams()
    const navigate = useNavigate()
    const [fetching, setFetching] = useState(false)
    const [courseName, setCourseName] = useState("")
    const [folderThumbnail, setFolderThumbnail] = useState("")
    const [excerpt, setExcerpt] = useState("")
    const [course_status, setCourseStatus] = useState("DRAFT")
    const [targetDate, setTargetDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [SnackbarComponent, showSnackbar] = useSnackbar();
    const [pickerOpen, setPickerOpen] = useState(false)
    const [editMode, setEditMode] = useState(true)

    const onInputChange = (e) => {
        setCourseName(e.target.value)
    }

    const onSave = async () => {
        setLoading(true)
        setError(null)
        var res;
        if (course_id && course_id !== "new") {
            res = await instance.patch(`/admin/training/course`, {
                name: courseName, thumbnail: folderThumbnail,
                excerpt: excerpt,
                course_id: course_id,
                target_date: targetDate,
                status: course_status
            })
        } else {
            res = await instance.post(`/admin/training/course`, {
                name: courseName, thumbnail: folderThumbnail,
                excerpt: excerpt, target_date: targetDate,
                status: course_status
            })
            // if success, navigate to /admin/training-course/id
            if (res.status === 200) {
                navigate(`/admin/training-course/${res.data?.data?.id}`)
            }
        }

        if (res.status !== 200) {
            setLoading(false)
            setError("Unable to save folder")
            showSnackbar("Unable to save course", "error")
            return
        }

        setLoading(false)
        setSuccess(true)
        setTimeout(() => {
            setSuccess(false)
        }, 1000)
        showSnackbar("Course saved successfully", "success")
    }

    useEffect(() => {
        // if id is new, set editMode to true
        if (course_id === "new") {
            setEditMode(true)
        } else {
            setEditMode(false)
        }
    }, [course_id])

    const fetchCourse = () => {
        setFetching(true)
        instance.get(`/admin/training/course?course_id=${course_id}`).then(res => {
            setCourseName(res.data.name)
            setFolderThumbnail(res.data.thumbnail)
            setExcerpt(res.data.excerpt)
            setTargetDate(res.data.target_date)
            onTargetDateChange(res.data.target_date)
            setCourseStatus(res.data.status)
            onCourseStatusChange(res.data.status)
            setFetching(false)
        }).catch(err => {
            console.log(err)
            setFetching(false)
        })
    }


    useEffect(() => {
        if (course_id && course_id !== "new") {
            fetchCourse()
        }
    }, [course_id])

    const onThumbSelect = (url) => {
        setFolderThumbnail(url)
        setPickerOpen(false)
    }



    return (
        <Box>
            <Box sx={{ py: 1, px: 2, backgroundColor: "#ddd", display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ py: 1 }}>
                    Course Details
                </Box>
                {course_id !== "new" &&
                    <IconButton size="small" onClick={() => setEditMode(!editMode)}>
                        {editMode ? <CloseIcon /> : <EditIcon />}
                    </IconButton>
                }
            </Box>
            <SnackbarComponent />
            {
                fetching ? <LoadingSkeleton /> :

                    <CardContent sx={{ px: 3 }}>
                        <Stack direction="row" spacing={4} sx={{ pt: 2 }}>
                            <Box sx={{ width: '50%' }}>
                                <CustomInputLabel sx={{ mb: 0 }}>
                                    Course Name
                                </CustomInputLabel>
                                {editMode ?
                                    <TextField sx={{ mt: 0.5 }}
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        type="text"
                                        size='small'
                                        fullWidth
                                        value={courseName}
                                        onChange={onInputChange}
                                    />
                                    :
                                    <TextContainer>
                                        {courseName}
                                    </TextContainer>
                                }

                                <Box sx={{ mt: 1 }}>
                                    <CustomInputLabel>
                                        Target Date
                                    </CustomInputLabel>
                                    {editMode ?
                                        <DatePicker name="target_date" value={targetDate} onChange={(e) => {
                                            setTargetDate(e.value)
                                            onTargetDateChange(e.value)
                                        }} />
                                        : <TextContainer> {targetDate ? targetDate.slice(0, 16) : "Not Set"}</TextContainer>
                                    }
                                </Box>
                                {/* <Box sx={{ mt: 1 }}>
                            <CustomInputLabel>
                                Status
                            </CustomInputLabel>
                            <Select size="small" fullWidth value={course_status} onChange={(e) => setCourseStatus(e.target.value)} name="course_status">
                                { ["DRAFT", "PUBLISHED"].map((status, index) => (
                                    <MenuItem key={index} value={status}>
                                        {status}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box> */}


                                <Box sx={{ mt: 1 }}>
                                    <CustomInputLabel>
                                        Excerpt
                                    </CustomInputLabel>
                                    {editMode ?
                                        <TextField multiline fullWidth rows={2} value={excerpt} onChange={(e) => setExcerpt(e.target.value)}
                                            placeholder='Excerpt (A short details/drescription about this module/folder)'
                                        />
                                        : <TextContainer> {excerpt}</TextContainer>}
                                </Box>

                            </Box>
                            <Box sx={{ width: '50%' }}>
                                <Stack direction="row" spacing={2} sx={{ mb: 1 }} justifyContent={"space-between"}>
                                    <CustomInputLabel>
                                        Thumbnail
                                    </CustomInputLabel>
                                    {editMode &&
                                        <Box sx={{
                                            color: "#09f", fontSize: '13px', textDecoration: 'underline',
                                            "&:hover": {
                                                cursor: 'pointer'
                                            }


                                        }} onClick={() => setPickerOpen(true)}>
                                            Select Thumbnail
                                        </Box>
                                    }
                                </Stack>
                                <MediaPicker open={pickerOpen} setOpen={setPickerOpen} onSelect={onThumbSelect} responseType='url' />
                                <Box sx={{
                                    display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '300px'
                                }}>
                                    {
                                        folderThumbnail ?
                                            <Box>
                                                <img src={folderThumbnail} style={{ height: 'auto', maxHeight: '240px', maxWidth: '90%' }} />
                                            </Box> : 
                                            <Box sx={{ height: '240px', width: '90%', border: '1px dashed #ccc', display: 'flex', 
                                                borderRadius: '4px',
                                            justifyContent: 'center', alignItems: 'center' }}>
                                                {/* <ImageIcon sx={{ fontSize: '70px', color: '#ccc' }} /> */}
                                            </Box>
                                    }
                                </Box>
                            </Box>
                        </Stack>



                        {error && <Alert severity="error">{error}</Alert>}
                        {editMode &&
                            <Stack direction="row" spacing={2} justifyContent={"flex-end"} sx={{ pt: 2 }}>

                                <Button onClick={onSave} color="primary" size='small' variant='contained' disabled={loading}>
                                    {loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Save"}
                                </Button>
                            </Stack>
                        }

                    </CardContent>
            }

        </Box>
    )
}


function LoadingSkeleton() {
    return (
        <Box sx={{ mt: 2, px: 3, mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                    {[1, 2, 3].map((item, index) => (
                        <Box key={index} sx={{ py: 1 }}>
                            <Skeleton variant="rectangular" height={30} sx={{ mb: 1 }} width="30%" />
                            <Skeleton variant="rectangular" height={40} />
                        </Box>
                    ))}
                </Grid>
                <Grid item md={6}>
                    <Skeleton variant="rectangular" height={200} />
                </Grid>

            </Grid>
        </Box>
    )
}