import React, { useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import { Card } from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import { styled } from '@mui/material/styles';
import CourseReport from "./CourseReport";
import UserReport from "./UserReport";


const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
}));


export default function AdminTrainingsCourseReport() {
    const [selectedTab, setSelectedTab] = useState("Course")
    const tabOptions = [
        { label: "Course", value: "Course" },
        { label: "User", value: "User" },
    ]

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    }

    return (
        <AppLayout title="Admin - Training Course"
            px={0} apppadding={"0px"}
        >
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Training Courses", url: "/admin/training" },
                        { text: "Course Progress" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >

                </Box>

            </BContainer>
            <Box sx={{ px: 2, my: 1 }}>
                <Card>
                    <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoice tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {
                            tabOptions.map((tab, tabIndex) => {
                                return <StyledTab
                                    style={{
                                        borderLeftWidth: tabIndex === 0 ? 1 : 0,
                                        backgroundColor: selectedTab !== tab.value ? "#F9F9F9" : undefined,
                                    }}
                                    label={`${tab.label}`} value={tab.value} key={tab.value} />
                            })
                        }
                    </Tabs>
                    {
                        selectedTab === "Course" && <CourseReport />
                    }
                    {
                        selectedTab === "User" && <UserReport />
                    }
                </Card>
            </Box>

        </AppLayout>
    )
}