import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog'
import instance from '../../../../../auth/utils/useJwt'
import { Box, Checkbox, CircularProgress, Grid, Stack, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { fetchUsers } from '../../../../../redux/users'

export default function CourseUsersAssignment({ onUpdate, assignedTrainingUsers }) {
    const { id: course_id } = useParams()

    const dispatch = useDispatch()
    const { data: usersData, loading: usersLoading } = useSelector(state => state.users)

    const [selectedUnitsDialog, setSelectedUnitsDialog] = useState(false)

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [assignedUsers, setAssignedUsers] = useState([])

    useEffect(() => {
        if (usersData.length === 0)
            dispatch(fetchUsers())
    }, [dispatch])

    useEffect(() => {
        if (assignedTrainingUsers.length > 0) {
            setAssignedUsers(assignedTrainingUsers.map(item => item.user_id))
        }
    }, [assignedTrainingUsers])

    const updateTrainingUsers = async () => {
        setLoading(true)
        setError(null)
        const data = {
            course_id,
            users: assignedUsers
        }
        instance.post(`/admin/training/course/users`, data).then(res => {
            setLoading(false)
            setSuccess(true)
            setSelectedUnitsDialog(false)
            onUpdate()
        }).catch(err => {
            setLoading(false)
            setError("Unable to update training units")
        })
    }
    

    const assignUser = (user) => {
        console.log(user)
        if (assignedUsers.includes(user.id)) {
            setAssignedUsers(assignedUsers.filter(item => item !== user.id))
            return
        }
        setAssignedUsers([...assignedUsers, user.id])
    }

    return (
        <Box>

            <Button size='small' variant='contained' color='primary' onClick={() => setSelectedUnitsDialog(true)}>
                Select Users
            </Button>
            <GeneralDialog open={selectedUnitsDialog} handleClose={() => setSelectedUnitsDialog(false)} maxWidth="md" title="Select Training Users"
                actions={<Stack direction="row" spacing={2}>
                    <Button color="primary" variant="contained" size="small"
                        disabled={loading}
                        onClick={updateTrainingUsers}
                    >
                        {loading ? <CircularProgress size={24} sx={{ color: "#fff" }} /> : "Save"}
                    </Button>
                </Stack>}
                dialogContentSx={{
                    p: 0
                }}
                dialogTitleSx={{
                    mb: 0
                }}
            >
                <Box sx={{ minWidth: '600px', maxHeight: '400px', overflowY: 'auto' }}>
                    <Grid container spacing={0.5} sx={{ p: 2 }}>
                        {
                            usersData?.map(user => (
                                <Grid item key={`user-${user.id}`} xs={12} sm={6} md={4}>
                                    <Stack direction="row" alignItems="center">
                                        <Checkbox size="small" onChange={() => assignUser(user)} checked={assignedUsers.includes(user.id)} />
                                        <Typography
                                            onClick={() => assignUser(user)}
                                            sx={{ fontSize: '14px', cursor: 'pointer' }}>{user.name}</Typography>
                                    </Stack>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </GeneralDialog>




        </Box>
    )
}