import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import {
    Button, IconButton, Grid, Typography, Divider

} from "@mui/material";
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
import VisibilityIcon from '@mui/icons-material/Visibility';
import parse from 'html-react-parser';
import '../../../Training/training-pages/SingleTraining/style.css'
export default function PreviewUnit({ unit }) {
    const [open, setOpen] = useState(false)

    const sections = unit?.sections
    return (
        <Box>
            <IconButton onClick={() => setOpen(true)}>
                <VisibilityIcon />
            </IconButton>

            <GeneralDialog maxWidth="lg" open={open} handleClose={() => setOpen(false)} title={unit.title + " Preview"}>
                <Box>
                    <Box>
                        {unit.content && parse(unit.content)}
                    </Box>
                </Box>
                {
                    sections &&
                    <Box>
                        {
                            sections.map((section_item, section_index) => {
                                return (
                                    <Box key={`section-${section_index}`}>

                                        <Typography
                                            variant="h6" sx={{ color: 'tale', my: 1 }}>
                                            Section {section_index + 1}. {section_item.title}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item md={7}>
                                                {section_item.content && parse(section_item.content)}
                                            </Grid>
                                            <Grid item md={5}>
                                                {section_item.quiz.map((quiz, quiz_index) => {
                                                    return (
                                                        <Box sx={{ py: 1 }}
                                                            key={`acsec-${section_index}-quiz-q-${quiz_index}`}>

                                                            <Box sx={{ color: "#06F", fontSize: '14px' }}>
                                                                {quiz.question}
                                                            </Box>
                                                            <ul>
                                                                {quiz.options.map((option, option_index) => {
                                                                    return (
                                                                        <li style={{ fontSize: '12px' }}
                                                                        key={`sec-${section_index}-quiz-${quiz_index}-option-${option_index}`}
                                                                        >
                                                                            {option}
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Box>

                                                    )
                                                })}
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 2 }} />

                                    </Box>
                                )
                            })}



                    </Box>
                }
            </GeneralDialog>
        </Box>
    )
}