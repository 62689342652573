import React, { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, styled } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import TableFooter from './comps/Footer';
import LoadingOverlay from './comps/overlays/LoadingOverlay';
import CustomToolbar from './comps/CustomToolbar';
import './style.css';
export const StyledDataGrid = styled(DataGrid)({
    '& .MuiDataGrid-columnHeader': {
        backgroundColor: '#fff', // Set table heading row background to #eee
        color: "#666",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 'bold'
    },

    //MuiDataGrid-columnHeader
    // Only for first column, add padding left to 5px
    '& .MuiDataGrid-columnHeader:first-of-type': {
        paddingLeft: '14px',
    },
    "& .MuiDataGrid-cell:first-of-type": {
        paddingLeft: '14px',
    },
    // Set border color to rgb(208, 210, 214)
    '& .MuiDataGrid-cell': {
        borderBottom: '1px solid #ddd', // Add vertical borders to cells
    },
    "& .MuiDataGrid-footerContainer": {
        display: 'none'
    }
});


export default function MUIDataTableX(props) {
    const { loading, data, cols: columns, NoRowsOverlay, density, customHeader, customHeaderSX,
        onRowSelectionModelChange,
        baseFilters, checkboxSelection } = props;

    const OverlayComp = loading ? LoadingOverlay : NoRowsOverlay
    const [visibleColumns, setVisibleColumns] = useState({})
    const [tableConfig, setTableConfig] = useState({
        page: 0,
        pageSize: 10,
        rowsPerPageOptions: [10, 20, 50, 100]
    });
    const [finalColumns, setFinalColumns] = useState([])
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 100 })
    const totalRows = data?.length
    const totalPages = Math.ceil(totalRows / tableConfig.pageSize)

    // create visible columns model
    useEffect(() => {
        const model = {}
        columns.forEach(col => {
            model[col.field] = col.visible === undefined ? true : col.visible
        })
        setVisibleColumns(model)

        // set first column a class
        // const firstColumn = columns[0]
        // firstColumn.cellClassName = 'mui-dt-first-column'
        setFinalColumns(columns)
    }, [columns])


    useEffect(() => {
        // Implement pagination based on data
        setPaginationModel({
            page: tableConfig.page,
            pageSize: tableConfig.pageSize
        })
    }, [tableConfig.page, tableConfig.pageSize, data])

    // On totalRows change, set page to 0
    useEffect(() => {
        setTableConfig({
            ...tableConfig, page: 0
        })
    }, [totalRows])

    return (
        <Box>
            <div style={{ width: '100%' }}>
                {customHeader &&
                    <Box sx={{
                        borderBottom: "1px solid #ccced2",
                    }}>
                        {customHeader}
                    </Box>
                }
                <StyledDataGrid
                    rows={data}
                    columns={finalColumns}
                    checkboxSelection={checkboxSelection}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    autoHeight
                    //loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 100 }
                        },
                    }}
                    density={density}
                    disableRowSelectionOnClick={true}
                    paginationModel={paginationModel}
                    sx={{
                        border: 'none', '--DataGrid-overlayHeight': '400px',
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}

                    // column visibility model
                    columnVisibilityModel={visibleColumns}
                    onColumnVisibilityModelChange={(model) => setVisibleColumns(model)}
                    pageSizeOptions={[10, 20, 50, 100]}
                    slots={{
                        loadingOverlay: LinearProgress,
                        toolbar: (props) =>
                            baseFilters ? <CustomToolbar customHeaderSX={customHeaderSX} visibleColumns={visibleColumns} setVisibleColumns={setVisibleColumns} columns={columns} {...props} /> :
                                null,
                        noRowsOverlay: OverlayComp
                    }}

                />
            </div>
            <Box>
                <TableFooter onChangePageSize={(val) => setTableConfig({
                    ...tableConfig, pageSize: val, page: 0
                })}
                    onChangePage={(val) => setTableConfig({
                        ...tableConfig, page: val
                    })}
                    totalRows={totalRows}
                    perPage={tableConfig.pageSize}
                    currentPage={tableConfig.page}
                    totalPages={totalPages}
                />
            </Box>
        </Box>
    );
}
