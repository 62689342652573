import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import { Button, IconButton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import MuiDataGrid from "../../../../components/tables/MUIDataGrid";
import DeleteCourse from "./DeleteDialog";
import useSnackbar from "../../../../components/Dialogs/SnackBar";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
export default function AdminTrainingsList() {
    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)

    const [SnackbarComponent, showSnackbar] = useSnackbar();

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get("/admin/training/course").then(res => {
            setLoading(false)
            console.log(res.data)
            setTrainings(res.data)
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])

    const onDelete = (id) => {
        setTrainings(trainings.filter(item => item.id !== id))
        showSnackbar("Course deleted successfully")
    }

    const COLUMNS = [
        {
            field: 'name', headerName: 'Course', flex: 1, renderCell: (params) => {
                return <Link style={{ color: "#06f" }} to={`/admin/training-course/${params.row.id}`}>{params.value}</Link>
            }
        },
        { field: 'organiser', headerName: 'Organiser', flex: 1 },
        { field: 'units', headerName: 'Units', width: 100 },
        { field: 'reading_minutes', headerName: 'Reading Minutes', width: 180 },
        { field: 'users', headerName: 'Users', width: 100 },
        { field: 'status', headerName: 'Status', width: 140 },
        {
            field: 'actions', headerName: 'Actions', width: 110, renderCell: (params) => {
                return (
                    <Stack direction="row" spacing={1}>
                        <IconButton component={Link} to={`/admin/training-course/${params.row.id}`} size="small">
                            <EditIcon sx={{ fontSize: '20px' }} />
                        </IconButton>
                        <DeleteCourse course={params.row} onDelete={onDelete} />
                    </Stack>
                )
            }
        }
        // { field: 'created_at', headerName: 'Created At', width: 200, renderCell: (params) => {
        //     return params.value?.slice(0, 16)
        // }},
        // { field: 'updated_at', headerName: 'Updated At', width: 200, renderCell: (params) => {
        //     return params.value?.slice(0, 16)
        // }}
    ]


    return (
        <AppLayout title="Admin - Training" px={0} apppadding={"0px"}>
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Training Courses" }
                    ]} />
                </Box>
                <SnackbarComponent />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }} >

                    <Button 
                    startIcon={<AddIcon />}
                    component={Link} to="/admin/training-course/new" variant="contained" size="small" color="primary">Add Course</Button>
                    {/* <Button component={Link} to="/admin/trainings/new" variant="contained" size="small" color="primary">Add Training</Button> */}
                    {/* <Button component={Link} to="/admin/training/units" variant="outlined" size="small" color="primary">Training Units</Button> */}
                    <Button component={Link} to="/admin/training/course-report" variant="outlined" size="small" color="secondary">
                        Course Report</Button>
                </Box>

            </BContainer>
            <Box>
                <MuiDataGrid data={trainings} loading={loading} columns={COLUMNS} baseFilters={false} />
            </Box>

        </AppLayout>
    )
}