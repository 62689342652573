import { Radio, Box, Stack } from "@mui/material";

export default function QuizOptions({ options, onChange, selected }) {
    return (
        <Box>
            {options.map((option, index) => (
                <Stack key={index} direction="row" alignItems="center">
                    <Radio size="small" sx={{ pl: 0, py: 0.5 }}
                        checked={selected === option}
                        onChange={() => onChange(option)}
                        value={option}
                        name="radio-buttons-group"
                    />
                    <span onClick={() => onChange(option)} style={{ cursor: "pointer", fontSize: '13px' }}>
                        {option}
                    </span>
                </Stack>
            ))}
        </Box>
    )
}