import { Button, Card, LinearProgress } from "@mui/material";
import Box from '@mui/material/Box'
import { useEffect, useState } from "react";
import CaseSearchInput from "./comps/SearchInput";
import CustomInputLabel from "../forms/CustomInputLabel";
import MUIDataTableX from "./MUIDataTableX";
import CustomNoRowsOverlay from "./comps/overlays/NoRowsOverlay";
import { Link } from "react-router-dom";


const filterDataBySearch = (allData, searchInput) => {
    const searchQuery = searchInput.toLowerCase();
    return allData.filter(row => {
        return Object.values(row).some(value =>
            value?.toString().toLowerCase().includes(searchQuery)
        );
    });
};

const filterByColumns = (allData, columns, searchInput) => {
    const searchQuery = searchInput.toLowerCase();
    return allData.filter(row => {
        return columns.some(col => {
            return row[col]?.toString().toLowerCase().includes(searchQuery)
        });
    });
};

function CardWrapper({ children }) {
    return (
        <Box sx={{ pb: 1, mx: 3 }}>
            <Card sx={{
                mb: 1,
                boxShadow: "0 0 0 1px rgba(0, 10, 30, .2)"
            }}>
                {children}
            </Card>
        </Box>
    )
}


export default function MuiDataGrid({
    loading, data, filters, columns, searchFunction, searchColumns = [], searchPlaceholder, searchLabel="Search",
    TabsView, checkboxSelection=false, onRowSelectionModelChange=null,
    noCard = false, density = "standard", customHeader = null, customHeaderSX = null, headerRightButtons = null,
    searchSX = {}, header = true, baseFilters = true,
    noRowsOverlay = CustomNoRowsOverlay
}) {
    const [searchInput, setSearchInput] = useState("")
    const [finalData, setFinalData] = useState([])

    const getSearchFunc = () => {
        if (searchFunction)
            return searchFunction(data, searchInput)
        if (searchColumns && searchColumns.length > 0)
            return filterByColumns(data, searchColumns, searchInput)
        return filterDataBySearch(data, searchInput)
    }

    //const searchFunc = getSearchFunc() //searchFunction || filterDataBySearch

    useEffect(() => {
        if (searchInput !== "") {
            setFinalData(getSearchFunc())
        } else {
            setFinalData(data)
        }
    }, [searchInput, data])

    const Wrapper = noCard ? Box : CardWrapper

    return (
        <Wrapper>

            {TabsView}
            {header &&
                <Box sx={{
                    display: 'flex', justifyContent: 'space-between',
                    borderBottom: '1px solid #ccced2', width: '100%',
                }}>
                    <Box sx={{
                        display: 'flex', py: 1.5, px: 1.6, gap: 2,
                    }}>
                        <Box>
                            <CustomInputLabel sx={{
                                fontSize: '13px', fontWeight: 700
                            }}>{searchLabel}</CustomInputLabel>
                            <CaseSearchInput searchSX={searchSX} 
                                searchInput={searchInput} setSearchInput={setSearchInput} 
                                searchPlaceholder={searchPlaceholder} />
                        </Box>
                        {filters}
                    </Box>
                    <Box>
                        {headerRightButtons}
                    </Box>
                </Box>
            }
            {loading &&
                <Box>
                    <LinearProgress />
                </Box>
            }
            
            <Box sx={{
            }}>
                <MUIDataTableX data={finalData} cols={columns} NoRowsOverlay={
                    noRowsOverlay || CustomNoRowsOverlay
                } loading={loading}
                    density={density}
                    checkboxSelection={checkboxSelection}
                    baseFilters={baseFilters}
                    customHeader={customHeader}
                    onRowSelectionModelChange={onRowSelectionModelChange}
                    customHeaderSX={customHeaderSX}
                />
            </Box>
        </Wrapper>
    )
}  