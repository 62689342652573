import { Box, Card } from "@mui/material";
import parse from 'html-react-parser'
export default function TrainingSection({section}){
    if (!section.content_list){
    return (
        <Card>
            {/* <Box sx={{ fontSize: '18px', fontWeight: '500', py: 1.6, px: 2, borderBottom: '1px solid #ccc' }}>
            {section.title}
            </Box> */}
            <Box sx={{ px: 2, py: 1.5 }}>
                {parse(section.content)}
            </Box>
        </Card>
    )
}
    if (section.content_list){
        return (
            <Box>
                {section.content_list.map((item, index) => (
                    <Card key={`section-${index}`} sx={{ mb: 1.5 }}>
                    <Box key={index} sx={{ px: 2, py: 1.5 }}>
                        {parse(item.content)}
                    </Box>
                    </Card>
                ))}
            </Box>
        )
    }
}