import React, { useState } from "react";
import Box from '@mui/material/Box'
import instance from "../../../../../auth/utils/useJwt";
import { Card, Skeleton } from "@mui/material";
import ReportFilters from "./Filters";
import StatsTable from "../components/StatsTable";

export default function CourseReport() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const get_progress = (course_id) => {
        setLoading(true)
        instance.get(`/admin/training/course/report?course_id=${course_id}`).then((res) => {
            console.log(res.data)
            setData(res.data)

            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }


    const onSubmit = (data) => {
        get_progress(data.course_id)
    }

    return (
        <Box>
            <Box sx={{ px: 2, my: 4 }}>

                <ReportFilters loading={loading} onSubmit={onSubmit} />
            </Box>

            {
                loading ?
                    <Box sx={{ px: 2, mb: 2 }}>
                        {
                            [1, 2, 3, 4].map((item, index) => {
                                return (
                                    <Box key={`skeleton-${index}`} sx={{ mb: 2 }}>
                                        <Card>
                                            <Box sx={{ backgroundColor: "#eee", px: 2, py: 1 }}>
                                                <Skeleton variant="text" width="100%" />
                                            </Box>
                                            <Box>
                                                <Skeleton variant="rectangular" width="100%" height={40} />
                                            </Box>

                                        </Card>
                                    </Box>
                                )
                            })

                        }
                    </Box>
                    :

                    <Box sx={{ px: 2, mb: 2 }}>
                        {data.map((item, index) => (
                            <Box key={`user-${item.user.user_id}`} sx={{ mb: 2 }}>
                                <Card>
                                    <Box sx={{ backgroundColor: "#A4A4A4", px: 2, py: 1, color: "#fff", fontWeight: 600 }}>
                                     {item.unit.title}: {item.user.email}
                                    </Box>
                                    <Box sx={{ backgroundColor: "#efefef", px: 2, py: 1 }}>
                                       Course: { item.course?.name }
                                    </Box>
                                    <StatsTable stats={item.progress} />
                                </Card>
                            </Box>
                        ))}
                    </Box>
            }
        </Box>
    )
}