import React, { useState } from "react";
import Box from '@mui/material/Box'
import instance from "../../../../auth/utils/useJwt";
import { Button, IconButton, Stack } from "@mui/material";
import GeneralDialog from "../../../../components/Dialogs/CustomDialog";
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteCourse({course, onDelete}) {
    const [open, setOpen] = useState(false)
    const [processing, setProcessing] = useState(false)
    const [error, setError] = useState(null)

    const deleteTrainingCourse = (id) => {
        setProcessing(true)
        //"/admin/training/course"
        instance.delete(`/admin/training/course?id=${id}`).then(res => {
            console.log(res.data)
            onDelete(id)
            setOpen(false)
            setProcessing(false)
        }).catch(err => {
            console.log(err)
            setError("An error occured")
            setProcessing(false)
        })
    }


    return (
        <Box>
            <IconButton onClick={() => setOpen(true)} size="small">
                <DeleteIcon sx={{ fontSize: '20px' }} />
            </IconButton>

            <GeneralDialog maxWidth="lg" open={open} handleClose={() => setOpen(false)} title={`Delete "${course.name}"`}
                actions={
                    <Stack direction="row" justifyContent="flex-end">
                        <Button size="small" onClick={() => deleteTrainingCourse(course.id)} variant="contained" color="error" disabled={processing}>
                            {processing ? "Deleting..." : "Delete"}
                        </Button>
                    </Stack>
                }
                >
                <Box>
                    Do you want to delete this course?
                </Box>
            </GeneralDialog>
        </Box>
    )
}