import { Box, styled } from "@mui/material";

export default function BaseCard({ title, children }) {
    return (
        <Box sx={{
            px: 2, pt: 3, pb: 2, border: '1px solid #ddd', position: 'relative'

        }}>
            {/* show title on parent box border */}
            <Box sx={{
                backgroundColor: "#1F2734", px: 2, py: 0.5
                , position: 'absolute', top: '-14px', left: '10px', color: '#fff', borderRadius: '5px'

            }}>
                {title}
            </Box>
            {children}
        </Box>
    )
}