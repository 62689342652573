import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {styled} from "@mui/material";
const THead = styled(TableHead)({
    backgroundColor: "#fefefe",
});

const TableCellC = styled(TableCell)({
    padding: '8px 20px',
    fontSize: '14px',
});

const ChipC = styled('div')({

    padding: '3px 10px',
    borderRadius: '20px',
    borderWidth: '1px',
    borderStyle: 'solid',
    fontSize: '11.5px',
    fontWeight: '500',
    display: 'inline-block',
});

function Chip ({text, color}) {

    const get_bg_color = (color) => {
        if (color === "success") {
            return "#D5F9E2"
        }else if (color === "warning") {
            return "#ffe0ce"
        }
        else if (color === "danger") {
            return "#F9ECEA"
        }
        else {
          return "#fff"
        }
    }

    const get_border_color = (color) => {

        if (color === "success") {
            return "#587A57"
        }
        if (color === "warning") {
            return "#e8b078"
        }
        if (color === "danger") {
            return "#E3C4BF"
        }
        return "#ddd"
    }

    return (
        <ChipC style={{
            backgroundColor: get_bg_color(color),
            borderColor: get_border_color(color)
        }}>{text}</ChipC>
    )
}


export default function StatsTable({ stats }) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} aria-label="training table">
        <THead>
          <TableRow>
            <TableCell>Training Session</TableCell>
            <TableCell align="right">Latest Testing</TableCell>
            <TableCell align="right">Due Date</TableCell>
            <TableCell align="right">Attempts</TableCell>
            <TableCell align="right">Proficiency Assessed</TableCell>
          </TableRow>
        </THead>
        <TableBody>
          {stats.map((row, index) => (
            <TableRow
              key={`row-${index}`}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCellC component="th" scope="row">
                {row?.title}
              </TableCellC>
              <TableCellC align="right">
              <Chip text={row.quizes?.[0].created_at || "N/A"} color={row.quizes?.[0].pass ? "success" : "danger"}/>

              </TableCellC>
              <TableCellC align="right">
                <Chip text={row["due_date"]} color={"warning"}/>
                    
                </TableCellC>
              <TableCellC align="right">{row.quizes?.length || "0"}</TableCellC>
              <TableCellC align="right">
                {/* {row["Proficiency Assessed"]} */}
                <Chip text={row["Proficiency Assessed"]} color={index % 2 === 0 ? "success" : "danger"}/>
              </TableCellC>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
