import React, { useEffect, useState } from "react";
import AppLayout from "../../../../components/layout/AppLayout";
import Box from '@mui/material/Box'
import BContainer from "../../../../components/BContainer";
import BreadCrumbs from "../../../../components/BreadCrumbs";
import instance from "../../../../auth/utils/useJwt";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MuiDataGrid from "../../../../components/tables/MUIDataGrid";

export default function AdminTrainingsCourseUserReport() {
    const { id: course_id, user_id } = useParams()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const get_progress = () => {
        setLoading(true)
        instance.get(`/admin/training/course/progress?course_id=${course_id}&user_id=${user_id}`).then((res) => {
            setData(res.data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        get_progress()
    }, [user_id])


    return (
        <AppLayout title="Admin - Training Course"
            px={0} apppadding={"0px"}
        >
            <BContainer>
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <BreadCrumbs data={[
                        { url: "#", text: "Admin" },
                        { text: "Training Courses", url: "/admin/training" },
                        { text: "Course Progress" }
                    ]} />
                </Box>
                <Box sx={{ display: 'flex' }} >
                    
                </Box>

            </BContainer>
            <Box sx={{ px: 3, mb: 2 }}>
                    <MuiDataGrid data={data} loading={loading} columns={[
                        { field: 'title', headerName: 'Title', flex: 1 },
                        { field: 'reading_minutes', headerName: 'Reading Minutes', width: 180 },
                        { field: 'completed', headerName: 'Completed', width: 180, renderCell: (params) => {
                            return params.row.user_progress ? <CheckCircleIcon sx={{ color: 'green' }} /> : <Typography variant="body2" color="error">Not Completed</Typography>
                        }},
                        { field: 'completed_at', headerName: 'Completed At', width: 180, renderCell: (params) => {
                            return params.row.user_progress ? params.row.user_progress?.created_at?.slice(0, 16) : "Not Completed"
                        }}
                    ]} />

            </Box>

        </AppLayout>
    )
}