import { useEffect, useState } from "react"
import instance from "../../../../../auth/utils/useJwt"
import { Box, Button, Card, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"

export default function UserTrainings({
    id
}) {

    const [trainingFolders, setTrainingFolders] = useState([])
    const [assignedTrainings, setAssignedTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    const get_trainings = () => {
        instance.get(`/admin/training/assignment?user_id=${id}`).then(res => {
            // Add index as index
            setAssignedTrainings(res.data?.trainings)
            setTrainingFolders(res.data?.folders)
            setLoading(false)
        }).catch(err => {
            setError(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        get_trainings()
    }, [])

    const isAssigned = (training_id) => {
        return assignedTrainings.find(t => t.folder_id === training_id) ? true : false
    }

    const handleAssign = (folder_id) => {
        // Update state
        let newAssignedTrainings = [...assignedTrainings]
        if (isAssigned(folder_id)) {
            newAssignedTrainings = newAssignedTrainings.filter(t => t.folder_id !== folder_id)
        } else {
            newAssignedTrainings.push({ folder_id })
        }
        setAssignedTrainings(newAssignedTrainings)
    }

    const updateAssignments = () => {
        setLoading(true)
        instance.post(`/admin/training/assignment`, {
            user_id: id,
            trainings: assignedTrainings
        }).then(res => {
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setError(err)
        })
    }

    return (
        <Box>
            <Card sx={{ boxShadow: "0 0 0 1px rgba(0, 10, 30, .2)" }}>
                <Typography variant="h6" sx={{ borderBottom: "1px solid #ddd", p: 2 }}>
                    Trainings Assignment
                </Typography>
                <Table size="small">
                    <TableHead>
                        <TableRow sx={{ borderBottom: "2px solid #ddd" }}>
                            <TableCell>
                                Training Course
                            </TableCell>
                            <TableCell>
                                Units
                            </TableCell>
                            <TableCell>
                                Reading Minutes
                            </TableCell>
                            <TableCell>
                                Assigned
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            trainingFolders.map((folder, index) => (
                                <TableRow key={`training-folder-${index}`} sx={{ borderBottom: "1px solid #ddd" }}>
                                    <TableCell>
                                        {folder.name}
                                    </TableCell>
                                    <TableCell>
                                        {folder.pages}
                                    </TableCell>
                                    <TableCell>
                                        {folder.reading_minutes}
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox size="small" checked={isAssigned(folder.id)} onChange={() => handleAssign(folder.id)} />
                                    </TableCell>
                                </TableRow>
                            ))
                        }

                    </TableBody>
                </Table>

                <Box sx={{ p: 2 }}>
                    <Button variant="contained" onClick={updateAssignments} disabled={loading}>
                        Save
                    </Button>
                </Box>


            </Card>
        </Box>
    )
}