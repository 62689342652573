import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'

import MuiDataGrid from '../../../../components/tables/MUIDataGrid'
import CourseUsersAssignment from './components/CourseUsersAssignment'
import instance from '../../../../auth/utils/useJwt'

export default function TrainingCourseUsers({onUsersChange, status}) {
    const { id: course_id } = useParams()

    const [assignedTrainingUsers, setAssignedTrainingUsers] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchCourseUsers = () => {
        setLoading(true)
        instance.get(`/admin/training/course/users?course_id=${course_id}`).then(res => {
            console.log(res.data)
            setAssignedTrainingUsers(res.data)
            onUsersChange(res.data)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        if (course_id && course_id !== "new") {
            fetchCourseUsers()
        }
    }, [course_id])




    return (
        <Box>
            <Stack direction="row" sx={{ py: 2, px: 2, backgroundColor: "#ddd" }} justifyContent={"space-between"}>
                <Typography>
                    Training Course Users
                </Typography>
                <Stack direction="row" spacing={2}>
                    {/* <Button size='small' variant="outlined" onClick={() => fetchCourseUsers()}>Refresh</Button> */}
                    { status !== "PUBLISHED" &&
                    <CourseUsersAssignment onUpdate={fetchCourseUsers} course_id={course_id} assignedTrainingUsers={assignedTrainingUsers} />
                    }
                </Stack>
            </Stack>

            <Box sx={{ mt: 2 }}>
                <MuiDataGrid
                    loading={loading}
                    baseFilters={false}
                    columns={[
                        { field: 'username', headerName: 'Name', flex: 1, renderCell: (params) => {
                            return <Link 
                                        style={{ color: "#06F" }} 
                                        to={`/admin/training-course/${course_id}/report/${params.row.user_id}`}>
                                            {params.row.username}
                                </Link>
                        } },
                        {
                            field: 'progress', headerName: 'Progress', width: 130, renderCell: (params) => {
                                return `${params.row.progress}/${params.row.total}`
                            }
                        },
                        {
                            field: 'last_activity', headerName: 'Last Activity', width: 150, renderCell: (params) => {
                                return params.row.last_activity ? params.row.last_activity.slice(0, 16) : "N/A"
                            }
                        },

                    ]} data={assignedTrainingUsers} />

            </Box>



        </Box>
    )
}