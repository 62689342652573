import { useEffect, useState } from "react";
import { Button, Card } from "@mui/material";
import AppLayout from "../../../components/layout/AppLayout";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import dayjs from 'dayjs';
import FiltersForm from "./Filters";
import instance from "../../../auth/utils/useJwt";
import BreadCrumbs from "../../../components/BreadCrumbs";
import DataGridTable from "./DataGridTable";
import { DATE_FORMAT } from "../../../configs/basic";
import BContainer from "../../../components/BContainer"

const initialColumnVisibility = {
    case_romac_id: true, // Assuming you want the ID column to be visible by default
    full_name: true,
    dob: false,
    status: true,
    acceptance: false,
    referral_date: true,
    country: true,
    classification: true,
    region: true,
    districts: true,
    hospital: true,
    clinicians: true,
    estimated_cost: false,
    actual_cost: false,
    sponsor: false,
    hosting_family: false,
    doctorLetter: false,
    hospitalLetter: false,
    immigrationDoc: false,
    letterOfAgreement: false,
    letterOfOffer: false,
    board_approval: false,
    passport_number: false,
    visa_expire: false,
    arrival_date: true,
    departure_date: true,
    medical_prognosis: false,
    prior_treatment: false,
    status_date: false,
    memo: false,
};


export default function ReportsPatientCases() {
    const [caseReports, setCaseReports] = useState([])
    const [pagination, setPagination] = useState({ page: 1, pageSize: 15, total_pages: 0, total_results: 0 })
    const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility);
    const [isFirstRequest, setIsFirstRequest] = useState(true)
    const [apiReq, setApiReq] = useState({
        loading: false, error: null
    })
    const [exportReq, setExportReq] = useState({
        loading: false, error: null
    })
    const [filters, setFilters] = useState({
        date: null,
        from: null,//dayjs().subtract(8, 'month').format(DATE_FORMAT),
        to: null, //dayjs().format(DATE_FORMAT), 
        status: null, region: null, district: null,
        condition: null, country: null, hospital: null, clinician: null, visa_expiry: false
    })

    const onClear = () => {
        setFilters({
            date: null,
            from: null,//dayjs().subtract(8, 'month').format(DATE_FORMAT),
            to: null, //dayjs().format(DATE_FORMAT), 
            status: null, region: null, district: null,
            condition: null, country: null, hospital: null, clinician: null, visa_expiry: false
        })
    }

    const fetchReports = () => {
        setApiReq({ loading: true, error: null })
        filters.page = pagination.page
        filters.pageSize = pagination.pageSize
        instance.post("/reports/case", filters).then(res => {
            setCaseReports(res.data.cases)
            setPagination({
                ...pagination, total_pages: res.data.total_pages,
                page: res.data.page,
                total_results: res.data.total_results
            })
            setApiReq({ loading: false, error: null })
            setIsFirstRequest(false)
        }).catch(err => {

            setApiReq({ loading: false, error: "Error fetching records" })
        })
    }

    // useEffect(() => {
    //     fetchReports()
    // }, [pagination.page, pagination.pageSize])

    const exportData = () => {
        setExportReq({ loading: true, error: null })
        var send_f = {
            export: true,
            columns: Object.keys(columnVisibility).filter((key) => columnVisibility[key] === true),
            ...filters
        }
        instance.post("/reports/case", send_f).then(res => {
            window.open(res.data, '_blank', 'noreferrer')
            setExportReq({ loading: false, error: null })
        }).catch(err => {

            setExportReq({ loading: false, error: "Error fetching records" })
        })
    }

    // useEffect(() => {
    //     fetchReports()
    // }, [])

    const breadcrumbs_v = [
        { url: "#", text: "Reports" },
        { text: "Patient Cases" },
    ]

    return (
        <AppLayout px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={breadcrumbs_v} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Card>
                    <Box sx={{
                        display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                        borderBottom: '1px solid #b8bfe3'
                    }}>
                        <Box sx={{ pt: 0.5, px: 1 }}>
                            <Typography variant="h6">Filters</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ p: 2 }}>
                        <FiltersForm filters={filters} setFilters={setFilters} fetchData={fetchReports} onClear={onClear} loading={apiReq.loading} />
                    </Box>
                </Card>

                {!isFirstRequest &&
                    <>
                        {filters.from && filters.to &&
                            <Box sx={{ textAlign: 'center', py: 2 }}>
                                <Typography variant="h5">Patient Case Report<br />
                                    ROMAC LTD.</Typography>
                                <Typography sx={{ mt: 2 }}>
                                    {filters.from && <>From: {filters.from}</>}
                                    {filters.to && <> To: {filters.to}</>}</Typography>

                            </Box>
                        }
                        <Card sx={{ mt: 1 }}>
                            <Box sx={{
                                display: 'flex', justifyContent: 'space-between', py: 1.5, px: 1,
                                borderBottom: '1px solid #b8bfe3'
                            }}>
                                <Box sx={{ pt: 0.5, px: 1 }}>
                                    <Typography variant="h6">Cases</Typography>
                                </Box>
                            </Box>

                            <DataGridTable data={caseReports} loading={apiReq.loading}
                                pagination={pagination} setPagination={setPagination}
                                columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility}
                            />

                            <Box sx={{ borderTop: '1px solid #eee', p: 2 }}>
                                <Button onClick={exportData} disabled={exportReq.loading} variant="contained">
                                    Export
                                </Button>
                            </Box>
                        </Card>

                    </>
                }





            </Box>

        </AppLayout>
    )
}