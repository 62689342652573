import React, { useState, useEffect } from 'react';
import GeneralDialog from '../../../../../components/Dialogs/CustomDialog';
import instance from '../../../../../auth/utils/useJwt';
import { Box, Divider, Grid } from '@mui/material';
import MediaUpload from './UploadFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
export default function MediaPicker({ open, onClose, onSelect, responseType='url' }) {

    const [files, setFiles] = useState([]);

    const fetchMedia = () => {
        // fetch media files
        instance.get('/admin/file').then(res => {
            setFiles(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchMedia()
    }, [])

    const onUpload = (file) => {
        // add file to files
        setFiles([...files, file])
    }

    const onFileSelect = (file) => {

        if (responseType === 'url') {
            return onSelect(file.url)
        }

        // if image, create <img /> element
        var html = ''
        if (file.type.startsWith('image')) {
            html = `<img src="${file.url}" alt="${file.name}" style="width: 100%;" />`
        }
        // if video, create <video /> element
        else if (file.type.startsWith('video')) {
            html = `<video src="${file.url}" controls style="width: 100%; min-height: 300px"></video>`
        }
        // if audio, create <audio /> element
        else if (file.type.startsWith('audio')) {
            html = `<audio src="${file.url}" controls style="width: 100%;"></audio>`
        }
        // if document, create <a /> element
        else {
            html = `<a href="${file.url}" target="_blank">${file.name}</a>`
        }
        // return the html
        onSelect(html)
    }


    return (
        <GeneralDialog open={open} handleClose={onClose} title={'Select Media'}>
            <Box>
                <MediaUpload onUpload={onUpload} />
            </Box>
            <Divider sx={{ my: 2 }} />
            <div>
                <Grid container spacing={2}>
                    {
                        files.map(file => (
                            <Grid item key={file.id} size={{ xs: 12, md: 3 }}>
                                <Box sx={{
                                    border: '1px solid #ccc', width: '120px'
                                }}>
                                <Box sx={{
                                    cursor: 'pointer', border: '1px solid #ccc',
                                    height: '100px', width: '100%', backgroundImage: `url(${file.thumbnail_url})`, backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                                    flexDirection: 'column',
                                    "&:hover": {
                                        opacity: 0.8
                                    }
                                }}

                                    onClick={() => onFileSelect(file)}>
                                        {
                                            // show video logo if file is video
                                            file.type.startsWith('video') ? <Box sx={{
                                                width: '40px', height: '40px',
                                                backgroundColor: 'rgba(0,0,0,0.3)',
                                                borderRadius: '50%',
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                flexDirection: 'column'
                                             }}>
                                                <OndemandVideoIcon sx={{
                                                    color: 'red'
                                                }} />
                                            </Box> : null
                                        }
                                </Box>
                                <Box sx={{
                                    fontSize: '11px', height: '30px', overflow: 'hidden', textOverflow: 'ellipsis', px: 1
                                }}>
                                    { file.name }
                                </Box>
                                </Box>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </GeneralDialog>
    )
}