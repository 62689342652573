import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Card, CardContent, LinearProgress, Stack, Button, Tab, Tabs, styled } from "@mui/material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import AppLayout from "../../../components/layout/AppLayout";
import BContainer from "../../../components/BContainer";
import BreadCrumbs from "../../../components/BreadCrumbs";
import instance from "../../../auth/utils/useJwt";
import CircularProgressFeedback from "../../../components/General/CircularProgressFeedback";
import MuiDataGrid from "../../../components/tables/MUIDataGrid";
import { Link } from "react-router-dom";



const StyledTab = styled(Tab)(({ theme }) => ({
    textTransform: 'none',
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
}));


export default function Training() {
    const [trainings, setTrainings] = useState([])
    const [loading, setLoading] = useState(true)
    const [progress, setProgress] = useState({
        completed: 0,
        total: 0
    })
    const [selectedTab, setSelectedTab] = useState(0)
    const [completedTrainings, setCompletedTrainings] = useState([])

    const fetchTrainings = async () => {
        setLoading(true)
        instance.get("/training?type=grouped").then(res => {
            setLoading(false)
            console.log(res.data)
            setTrainings(res.data?.trainings)
            setCompletedTrainings(res.data?.trainings.filter(t => t.completed === t.trainings?.length))
            setProgress({
                completed: res.data?.completed,
                total: res.data?.total
            })
        }).catch(err => {
            console.log(err)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchTrainings()
    }, [])


    // Open a new window with half size in center of screen
    const openWindow = (url) => {
        const width = window.innerWidth / 1.4;
        const height = window.innerHeight / 1.2;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);

        const newWindow = window.open(url, "", `width=${width}, height=${height}, top=${top}, left=${left}`);

        const checkWindowClosed = setInterval(() => {
            if (newWindow.closed) {
                clearInterval(checkWindowClosed);
                console.log("Window was closed");
                fetchTrainings()
            }
        }, 1000); // Check every second
    }


    const get_button_text = (progress) => {
        console.log(progress)
        if (progress.completed === 0) {
            return "Start"
        } else if (progress.completed === progress.trainings?.length) {
            return "Completed"
        } else {
            return "Resume"
        }

    }

    const tabOptions = [
        { label: "Current", value: 0 },
        { label: "Completed", value: 1 },
    ]

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }
    



    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training" },
                    { text: "My Courses" }
                ]} />
            </BContainer>
            <Box sx={{ py: 1, pr: 3 }}>
                <Grid container spacing={1}>
                    <Grid item md={8}>
                        <MuiDataGrid data={selectedTab === 0 ? trainings.filter(t => t.completed !== t.trainings?.length) : completedTrainings}
                        TabsView={
                            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="invoice tabs" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                {
                                    tabOptions.map((tab, tabIndex) => {
                                        return <StyledTab
                                        style={{
                                            borderLeftWidth: tabIndex === 0 ? 1 : 0,
                                            backgroundColor: selectedTab !== tab.value ? "#F9F9F9" : undefined,
                                        }}
                                        label={`${tab.label} (${tab.value === 0 ? trainings.filter(t => t.completed !== t.trainings?.length).length : completedTrainings.length}
                                        )`} value={tab.value} key={tab.value} />
                                    })
                                }
                            </Tabs>
                        }
                            baseFilters={false}
                            columns={[
                                { field: 'name', headerName: 'Course', flex: 1 },
                                
                                {
                                    field: 'target_date', headerName: 'Target Date', width: 150, renderCell: (params) => {
                                        return params.value?.slice(0, 16)
                                    }
                                },
                                {
                                    field: 'training_units', headerName: 'Units', width: 120, renderCell: (params) => {
                                        return params.row.trainings?.length
                                    }
                                },
                                {
                                    field: 'completed', headerName: 'Progress', width: 159, renderCell: (params) => {
                                        // if completed is 0, then say not started, else % completed
                                        return params.value === 0 ? "Not Started" : `${params.value / params.row.trainings?.length * 100}% Completed`
                                    }
                                },
                                
                                {
                                    field: 'actions', headerName: 'Actions', width: 130, renderCell: (params) => {
                                        return (
                                            <Stack direction="row" spacing={1}>
                                                <Button variant="contained"
                                                    endIcon={<ArrowForwardIcon />}
                                                    sx={{
                                                        textTransform: 'capitalize'
                                                    }} size="small"
                                                    component={Link}
                                                    to={`/training/${params.row.slug}/units`}
                                                    // onClick={() => openWindow(`/training/${params.row.slug}`)}
                                                >
                                                    {get_button_text(params.row)}
                                                </Button>
                                            </Stack>
                                        )
                                    }
                                }
                            ]}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <Box sx={{
                                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                            }}>Overall Progress</Box>
                            <CardContent>
                                <Typography variant="subtitle2">You have completed {
                                    progress.completed
                                } out of {
                                        progress.total
                                    } trainings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    )










    return (
        <AppLayout title="Training" px={0} apppadding={"0px"}>
            <BContainer>
                <BreadCrumbs data={[
                    { text: "Training" }
                ]} />
            </BContainer>
            <Box sx={{ px: 3, py: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        {/* <Card> */}
                        {loading && <LinearProgress />}
                        <Box>

                            {
                                trainings.map((training, index) => (
                                    <Card key={index} sx={{ mb: 2 }}>
                                        <Stack direction="row" gap={1} alignItems="center">
                                            <Box>
                                                <Box style={{
                                                    width: "300px", height: "200px",
                                                    backgroundImage: `url(${training.folder.thumbnail})`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    borderRadius: "5px",
                                                    border: "1px solid #ddd",
                                                    cursor: "pointer",
                                                }}></Box>
                                            </Box>
                                            <Box sx={{ px: 3 }}>
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Typography variant="h5">{training.folder.name}</Typography>
                                                    {training.completed === training.trainings?.length && <GridCheckCircleIcon style={{ color: "#06F", fontSize: "1.3rem" }} />}
                                                </Stack>
                                                <Typography variant="subtitle2" sx={{
                                                    color: "#666", fontSize: "0.8rem"
                                                }}>{`Total Estimated Time: ${training.trainings.reduce((acc, t) => acc + t.reading_minutes, 0)
                                                    } minutes`}

                                                </Typography>
                                                <Stack direction={"row"} gap={2} sx={{ mt: 2 }}>
                                                    <Box>
                                                        <Button variant="contained"
                                                            endIcon={<ArrowForwardIcon />}
                                                            sx={{
                                                                textTransform: 'capitalize'
                                                            }} size="small"
                                                            onClick={() => openWindow(`/training/${training.folder.slug}`)}
                                                        // component={Link} to={`/training/${training.folder.slug}`}
                                                        >
                                                            {/* {training.completed === 0 ? "Start" : "Resume"}
                                                             */}
                                                            {get_button_text(training)}
                                                        </Button>
                                                    </Box>
                                                    <Stack direction={"row"} gap={1} sx={{ pt: 0.3 }}>
                                                        <CircularProgressFeedback variant="determinate"
                                                            size={25}
                                                            value={(training.completed / training.trainings?.length) * 100} />
                                                        {Math.round((training.completed / training.trainings?.length) * 100)}%
                                                    </Stack>
                                                </Stack>
                                            </Box>
                                        </Stack>

                                    </Card>
                                ))
                            }



                        </Box>

                        {/* </Card> */}
                    </Grid>
                    <Grid item md={4}>
                        <Card>
                            <Box sx={{
                                borderBottom: '1px solid #ddd', p: 2, fontSize: "1.2rem", fontWeight: 500
                            }}>Progress</Box>
                            <CardContent>
                                <Typography variant="subtitle2">You have completed {
                                    progress.completed
                                } out of {
                                        progress.total
                                    } trainings</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </AppLayout>
    )
}